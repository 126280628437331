@import './colors.scss';
@import './breakpoints.scss';

.rs-blue-button {
  background-color: $primary;
  color: white;
  height: 50px;
  font-size: 20px;
  width: 100%;
  margin: 15px 0;
  text-transform: uppercase;

  &:disabled {
    color: white;
  }
}

.rs-link {
  font-size: 14px;
  font-weight: bold;
  color: $primary;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  &_disabled {
    color: gray;
    cursor: default;
    text-decoration: none;
  }
}

.rs-link-button {
  font-size: 14px;
  font-weight: bold;
  color: $primary;
  padding-left: 0 !important;
}

.rs-step-title {
  font-size: 24px;
  color: $text;
}

.rs-step-title-bold {
  font-size: 24px;
  font-weight: bold;
  color: $text;
}

.rs-step-title-bold-small {
  font-size: 16px;
  font-weight: bold;
  color: $text;
}

.rs-step-title-normal-small {
  font-size: 16px;
  font-weight: normal;
  color: $text;
}

.rs-line {
  width: 100%;
  height: 1px;
  background-color: $line;
}

.rs-message {
  margin-bottom: 18px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;

    mat-icon {
      margin-left: 5px;
      font-size: 30px;
      height: 30px;
    }
  }
}

.rs-enrollment-section {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 200px;

  &__content {
    width: 780px;
  }
}
.rs-row {
  display: flex;

  &> * {
    flex: 1;
    width: 100%;

    mat-form-field, rs-percent-control, rs-control-with-mask {
      width: 100%;
    }
    &:nth-child(2) {
      margin-left: 20px;
    }
  }

  &.full-width {
    mat-form-field {
      margin-right: 0;
    }
  }
}

.rs-owners-subtitle {
  font-size: 16px;
  font-weight: 300;
  color: $text;
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  mat-icon {
    margin-left: 10px;
    color: $icon;
    font-size: 18px;
    margin-top: 5px;
  }
}


.rs-divider {
  height: 1px;
  margin: 20px 0;
  background-color: $line;
}

.rs-content-bold {
  font-size: 18px;
  font-weight: 500;
}

.rs-sub-info {
  display: flex;
  font-size: 16px;

  mat-icon {
    color: $primary;
    font-size: 20px;
    margin: 0 10px;
  }
}

.rs-review-step {
  font-size: 20px;
  font-weight: 500;
  color: $text;
  margin-top: 36px;
  margin-bottom: 20px;
}
.rs-review-value {
  font-size: 16px;
  font-weight: 300;
  color: $text;
}

.rs-single-cancel-btn {
  button {
    width: 50%;
    margin: 0 auto;
    display: block;
    background-color: $primary;
    color: #fff;
    font-size: 20px;
  }
}

@media (max-width: 1105px) {
  .rs-step-title-bold {
    display: inline-block;
    text-align: center;
  }

  .rs-step-title-bold {
    margin: 0 auto;
    width: 90% !important;
    display: block;
  }

  .rs-row {
    display: block;

    &> * {
      &:nth-child(1) {
        margin-right: 15px;
      }
      &:nth-child(2) {
        margin-left: 0px !important;
      }
    }
  }
}

@media(max-width: $laptop) {
  .rs-enrollment-section {
    &__content {
      padding: 0 25px;
    }
  }
}

@media(max-width: $tablet) {
  .rs-enrollment-section {
    &__content {
      width: 100%;
    }
  }
  .notifier__notification {
    max-width: 90% !important;
  }
}

@media(max-width: $mobile) {
  .rs-message {
    padding: 10px !important;
  }
  .rs-message {
    &__header {
      font-size: 16px;

      mat-icon {
        margin-left: 5px;
        font-size: 25px;
        height: 25px;
      }
    }
    &__content {
      font-size: 13px;
    }
  }
  .rs-blue-button {
    height: auto;

    .mat-button-wrapper {
      white-space: break-spaces;
    }
  }
  .rs-row {
    flex-direction: column;
  }
}
