@import "../scss/colors.scss";
@import "../scss/breakpoints.scss";

.rs-form-control-linear {

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 2px;
  }

  .mat-form-field-empty.mat-form-field-label,
  .mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label {
    color: $input-linear-placeholder;
  }

}

.rs-form-control-outline {
  .mat-form-field {
    transform: translateY(-4px);
  }
  mat-error {
    font-size: 12px;
  }
  .mat-form-field-outline {
    height: 60px;
  }
  .mat-form-field-appearance-outline .mat-form-field-flex {
    height: 60px;
    padding-left: 20px;
  }
  .mat-form-field-empty.mat-form-field-label,
  .mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label {
    font-size: 16px;
    position: relative;
    top: 15px;
  }
  input {
    font-size: 16px;
  }
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.54em) scale(0.75) !important;
  }
}

.mat-dialog-container {
  position: relative;
}

.mat-radio-outer-circle {
  border-width: 1px !important;
}
.mat-radio-group {
  display: flex !important;
  flex-direction: column !important;
}
.mat-radio-button {
  margin-bottom: 30px;
  width: fit-content;

  label {
    margin-bottom: 0 !important;
    font-size: 16px !important;
    font-weight: 300 !important;
    color: $text !important;

    .mat-radio-label-content {
      padding-left: 30px !important;
    }
  }
}

.mat-checkbox-inner-container {
  width: 26px !important;
  height: 26px !important;
}
.mat-checkbox-frame {
  border-width: 1.5px !important;
  border-color: $checkbox-border !important;
  border-radius: 8px !important;
}
.mat-checkbox-disabled {
  .mat-checkbox-frame {
    border-color: $disabled-checkbox !important;
  }
}
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: transparent !important;
  background-image: url("/assets/new-img/agree.svg") !important;
  svg {
    display: none !important;
  }
}
.mat-checkbox-label {
  font-size: 16px;
  color: $text;
  line-height: 26px !important;
  margin-left: 10px;
}
.mat-checkbox.mat-accent {
  .mat-checkbox-inner-container {
     margin: 2px 8px auto 0;
  }
}

.mat-form-field-type-mat-select {
  .mat-form-field-outline {
    height: 60px !important;
  }
  .mat-form-field-label, .mat-select-min-line {
    font-size: 16px !important;
  }
  .mat-select-arrow {
    border: none !important;
    background-image: url("/assets/img-new/arrow.png") !important;
    height: 17px !important;
    width: 25px !important;
    background-position: 0 0;
    background-repeat: no-repeat;
    margin-top: 10px !important;
    background-size: 50%;
    margin-right: -3px !important;
  }
  .mat-form-field-flex {
    padding-left: 20px !important;
  }
}

.rs-phone-control {
  .iti {
    width: 100%;

    input {
      height: 60px !important;
      width: 100%;
      margin-right: 0 !important;
      border-radius: 4px;
      border: 1px solid $input-line !important;
      font-size: 16px;
      outline: none;
      padding-left: 50px;
      color: $text;
      transition: .3s;

      &:hover {
        border-width: 2.22px !important;
        border-color: $text !important;
      }
      &:focus {
        border-width: 2.22px !important;
        border-color: $primary !important;
      }
      &.ng-invalid.ng-touched {
        border-width: 2.22px !important;
        border-color: $warn !important;
      }
    }
    &__flag-container {
      height: 60px !important;
    }
    &__selected-flag {
      outline: none;
    }
  }

  &_linear {
    padding-top: 0 !important;

    .iti {
      input {
        height: 50px !important;
        border: none !important;
        border-radius: 0px !important;
        border-bottom: 2.2px solid $input-line !important;
        margin-bottom: 17px !important;

        &:hover {
          border-color: $input-line !important;
        }
        &:focus {
          border-width: 2.22px !important;
          border-color: $primary !important;
        }
      }
      &__flag-container {
        height: 50px !important;
      }
    }
  }
}

.rs-form-control-outline-bordered {
  .mat-form-field-appearance-outline
  .mat-form-field-outline {
    background-color: $main-white !important;
    border-radius: 8px;
  }

  label {
    color: $input-linear-placeholder;
  }

}

.rs-radio-flexed-row {
  display: flex;
  flex-direction: row !important;

  .mat-radio-button label .mat-radio-label-content {
    padding-left: 0 !important;
    font-weight: 500;
    color: $text;
    margin-left: 15px;
  }

}

.notifier__notification {
  max-width: 600px;

  &-message {
    line-height: 20px !important;
  }
}

@media(max-width: $tablet) {
  .mat-checkbox-label, .mat-radio-label-content {
    white-space: normal !important;
  }
}
