$navigation: #dddddd;
$text: #333;
$scroll-bar-thumb: rgb(102, 102, 102);
$scroll-bar: #f4f4f4;
$primary: #00a7ef;
$warn: #fb1d09;
$line: #979797;
$border: #999999;
$message-bgc: #f1f5f7;
$checkbox-border: #5a5a5a;
$input-linear-placeholder: #cdcdcd;
$icon: #4c6278;
$spinner: #fa6400;
$subtext: #7e7e7e;
$date: #a6a6a6;
$inactive: #d9d9d9;
$input-line: #dfe2e4;
$disabled-icon-label: #999;
$main-white: #fff;
$yellow-icon: #f7b500;
$disabled-icon: #ddd;
$grey-icon: #6d7278;
$important-info: #e02020;
$list-border: #e2e2e2;
$light-list-border: #f3f5f7;
$grey-bgc: #f3f5f7;
$dotted-menu: #d8d8d8;
$complete-status: #92c16f;
$disabled-checkbox: #d8d8d8;
